import { Box, Tooltip } from '@mui/material'
import { ITranscriptionPhrases } from 'app/codecs'
import i18n from 'i18next'
import { formatConfidenceScore } from 'imgplay-domain/format'
import { Fragment, MutableRefObject } from 'react'
import { VideoJsPlayer } from 'video.js'

export const renderWords = (
  group: ITranscriptionPhrases,
  playerRef: MutableRefObject<VideoJsPlayer | null>,
  searchText: string,
  elementId?: string,
) => {
  const currentVideoTime = playerRef.current?.currentTime() ?? 0

  return (
    <Box display="inline">
      {group.map(chunk => (
        <span
          key={chunk.alternativeId}
          style={{
            backgroundColor:
              currentVideoTime >= chunk.startTime
                ? 'rgba(74, 195, 152, 0.3)'
                : undefined,
          }}
        >
          {chunk.words.map((word, idx, words) => {
            return word.type === 'punctuation' ? (
              word.content === searchText ? (
                <span style={{ backgroundColor: '#F2994A' }}>
                  {word.content + (words[idx + 1]?.type === 'word' ? ' ' : '')}
                </span>
              ) : (
                word.content + (words[idx + 1]?.type === 'word' ? ' ' : '')
              )
            ) : (
              <Fragment key={word.startTime + word.content + idx}>
                {word.confidence !== null && word.confidence < 0.7 ? (
                  <Tooltip
                    title={`${i18n.t(
                      'labels.confidence_score',
                    )}: ${formatConfidenceScore(word.confidence)}`}
                  >
                    <span
                      style={{
                        cursor: 'pointer',
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: '22px',
                        backgroundColor:
                          searchText &&
                          word.content
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                            ? elementId === chunk.alternativeId
                              ? '#F2994A'
                              : '#F2E14A'
                            : undefined,
                        textDecoration:
                          word.confidence < 0.7 ? 'underline' : 'none',
                        textDecorationColor:
                          word.confidence < 0.3
                            ? '#C1121F'
                            : word.confidence < 0.7
                            ? '#F2994A'
                            : '#747474',
                        textDecorationThickness: 2,
                      }}
                      onDoubleClick={() => {
                        playerRef.current?.currentTime(word.startTime)
                        if (playerRef.current?.paused()) {
                          playerRef.current.play()
                        }
                      }}
                    >
                      {word.content}
                    </span>
                  </Tooltip>
                ) : (
                  <span
                    style={{
                      cursor: 'pointer',
                      fontSize: 14,
                      fontWeight: 500,
                      lineHeight: '22px',
                      backgroundColor:
                        searchText &&
                        word.content
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                          ? elementId === chunk.alternativeId
                            ? '#F2994A'
                            : '#F2E14A'
                          : undefined,
                      textDecoration:
                        word.confidence !== null && word.confidence < 0.7
                          ? 'underline'
                          : 'none',
                      textDecorationColor:
                        word.confidence !== null && word.confidence < 0.3
                          ? '#C1121F'
                          : word.confidence !== null && word.confidence < 0.7
                          ? '#F2994A'
                          : '#747474',
                      textDecorationThickness: 2,
                    }}
                    onDoubleClick={() => {
                      playerRef.current?.currentTime(word.startTime)
                      if (playerRef.current?.paused()) {
                        playerRef.current.play()
                      }
                    }}
                  >
                    {word.content}
                  </span>
                )}
                {words[idx + 1]?.type === 'word' ? ' ' : ''}
              </Fragment>
            )
          })}{' '}
        </span>
      ))}
    </Box>
  )
}
